import React, { lazy, Suspense } from 'react';
//import Routes from "./Routes";
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';



const IndexPage = React.lazy(() => import("pages/Web"));
 
const ComingSoon = React.lazy(() => import("pages/Comingsoon"));


const SignUp = React.lazy(() => import("pages/SignUpV1"));
const SignIn = React.lazy(() => import("pages/SignIn"));

/* after login teacher pages */
const Profile = React.lazy(() => import("pages/Profile"));
//const Jobs = React.lazy(() => import("pages/Jobs"));
const Jobs = React.lazy(() => import("pages/JobsV1"));
const UserSettings = React.lazy(() => import("pages/UserSettings"));
const UserSettingsPassword = React.lazy(() => import("pages/UserSettingsPassword"));
const UserSettingsMyDetails = React.lazy(() => import("pages/UserSettingsMyDetails"));
const YourJobsApplied = React.lazy(() => import("pages/YourJobsApplied"));
const MySavedJobs = React.lazy(() => import("pages/MySavedJobs"));
const MyJobAlerts = React.lazy(() => import("pages/MyJobAlerts")); 
const PrivacySettings = React.lazy(() => import("pages/PrivacySettings"));
const ViewJob = React.lazy(() => import("pages/ViewJob"));

const Home = React.lazy(() => import("pages/Home"));
const NotFound = React.lazy(() => import("pages/NotFound"));

const Courses = React.lazy(() => import("pages/Courses"));
const Blogs = React.lazy(() => import("pages/Blogs"));
const BlogsByCategory = React.lazy(() => import("pages/BlogsByCategory"));

const Logout = React.lazy(() => import("pages/Logout"));
const Postablog = React.lazy(() => import("pages/Postablog"));
const Notifications = React.lazy(() => import("pages/Notifications"));
const SearchJobs = React.lazy(() => import("pages/SearchJobs"));

const AboutUs = React.lazy(() => import("pages/AboutUs"));
const WhyChooseUs = React.lazy(() => import("pages/WhyChooseUs"));
const Advisory = React.lazy(() => import("pages/Advisory"));

const TermsAndConditions = React.lazy(() => import("pages/Termsandconditions"));
const PrivacyPolicy = React.lazy(() => import("pages/PrivacyPolicy"));
const Pricing = React.lazy(() => import("pages/Pricing"));
const PricingForInst = React.lazy(() => import("pages/PricingForInst"));

function App() {
  return (

    <React.Suspense fallback={<>Loading...</>}>

    <Router>
        <Switch>
          <Route path="/" exact component={Home} /> 
          <Route path="/home" exact component={Home} />  
          <Route path="/signin" exact component={SignIn} />  
          <Route path="/signup" exact component={SignUp} /> 
          <Route path="/logout" exact component={Logout} />  

          <Route path="/privacypolicy" exact component={PrivacyPolicy} />  
          <Route path="/termsandconditions" exact component={TermsAndConditions} />  

          <Route path="/pricing" exact component={Pricing} />  
          <Route path="/pricinginstitute" exact component={PricingForInst} />
          
          { /* after login Teachers */ }
          <Route path="/profile" exact component={Profile} />  
          <Route path="/jobs/:keyword/:location" exact component={Jobs} />  
          <Route path="/jobs" exact component={Jobs} />  
          <Route path="/usersettings" exact component={UserSettings} /> 
          <Route path="/password" exact component={UserSettingsPassword} /> 
          <Route path="/mydetails" exact component={UserSettingsMyDetails} /> 
          <Route path="/privacysettings" exact component={PrivacySettings} /> 
          <Route path="/applied" exact component={YourJobsApplied} /> 
          <Route path="/savedjobs" exact component={MySavedJobs} /> 
          <Route path="/jobalerts" exact component={MyJobAlerts} /> 
          <Route path="/courses" exact component={Courses} /> 
          <Route path="/blog" exact component={Blogs} /> 
          <Route path="/blogs/:category" exact component={BlogsByCategory} /> 
          <Route path="/postanarticle" exact component={Postablog} /> 
          <Route path="/notifications" exact component={Notifications} /> 

          <Route path="/aboutus" exact component={AboutUs} /> 
          <Route path="/whychooseus" exact component={WhyChooseUs} /> 
          <Route path="/advisory" exact component={Advisory} /> 
          <Route path="/searchjobs" exact component={SearchJobs} /> 
          
          { /* common */ }
          <Route path="/viewjob/:jobid" exact component={lazy(() => import('./pages/ViewJob'))} /> 
          <Route path="/institutes/viewjob/:jobid" exact component={lazy(() => import('./pages/ViewJobInstitute'))} /> 

          <Route path="/viewarticle/:slug_term" exact component={lazy(() => import('./pages/ViewArticle'))} /> 
 
          {/* institutes */ }
          <Route path="/institutes/applicants/:jobid" exact component={lazy(() => import('./pages/InstitutesJobsApplicants'))} /> 
          <Route path="/institutes/dashboard"  exact component={lazy(() => import('./pages/InstitutesDashboard'))} /> 
          <Route path="/institutes/applicants" exact component={lazy(() => import('./pages/InstitutesJobsApplicants'))} /> 
          <Route path="/institutes/postajob" exact component={lazy(() => import('./pages/postajob'))} /> 
          <Route path="/institutes/editjob/:jobid" exact component={lazy(() => import('./pages/editjob'))} />  

          
          <Route path="*" exact component={lazy(() => import('./pages/NotFound'))} />  
        
        </Switch>

    </Router>
   
    </React.Suspense>

  )
}

export default App;
