import React from "react";


import "./styles/color.css";
import "./styles/font.css";
import ReactDOM from "react-dom";
import App from "./App";

//import 'bootstrap/dist/css/bootstrap.css';
//import 'bootstrap/dist/js/bootstrap.js';

import "./styles/index.css";
import "./styles/mobile.css";
import "./styles/tailwind.css";
import "./styles/style.css";
import "./styles/modal.css";

import "./styles/viewports.css";

import * as Sentry from "@sentry/react";


Sentry.init({
  dsn: "https://a903e70c415696db248afdbaf158b914@o4507100177039360.ingest.de.sentry.io/4507128415191120",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ["bewise.in", /^https:\/\/api.bewise\.in\/api/],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});


ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);
